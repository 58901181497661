<template>
    <div class="no-found">
        404
    </div>
</template>

<script>
export default {

}
</script>

<style>

</style>